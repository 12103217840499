import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import { Button } from 'antd';
import logo from '../images/amesp.jpg';

const Container = styled.div`
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: space-between;
  align-content: center;

  & code {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: .2em .4em;
    font-size: 85%;
  }

  & p {
    text-align: left;
    font-size: 1.1rem;
    font-weight: 500;
  }

  .logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    min-width: 300px;
    max-width: 400px;
  }

  .logo-text {
    font-size: 1.5rem;
    font-weight: 600;
  }

  .body-text {
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-around;
    width: 60%;
}
`

function IndexPage() {
  return (
    <Layout hasSider={false} title="">
        <Container>
            <div class="body-text">
                <p style={{ color: "red", fontSize: "1.4rem" }}>
                Atomic and molecular electronic structure program
                </p>
                <p>
                Atomic and molecular electronic structure program (Amesp) is an ab initio, density functional and semiempirical methods quantum chemistry program. It has been independently developed by Zhang Yingfeng since 2016-03-09. Amesp supports many WFT and DFT methods and their first-order and second-order analytical derivatives. It supports excited state calculations, and can consider solvation and relativistic effects. It can perform geometric structure optimization and vibration analysis. SCF adopts many methods to improve the stability and convergence, while using the methods such as RI and COSX to accelerate the calculation of energy and energy derivative.
                </p>
                
            </div>

            <div class="logo">
                <div style={{ textAlign: "center" }}>
                    <img src={logo} alt="logo" style={{ width: "80%"}}/>
                </div>
                <div class="logo-text">
                    Amesp
                </div>
                <div className='friend-link'>
                    Friend link: <a href="https://gitlab.com/jxzou/mokit">MOKIT</a>
                </div>
            </div>
        </Container>

    </Layout>
  )
}

export default IndexPage;